import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../../components/layout"
import { CollectionListing } from "../../components/collection-listing"
import { Seo } from "../../components/seo"
import { MoreButton } from "../../components/more-button"

export default function Products({ data: { collections  } }) {
  return (
    <Layout>
      <Seo title="All Collections" />
      <section>
        <div className="container">
          <h1 className="title">Collections</h1>
        </div>
      </section>
      <div className="container">
        <CollectionListing products={collections.nodes} />
        {collections.pageInfo.hasNextPage && (
          <MoreButton to={`/search#more`}>More products</MoreButton>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    collections: allShopifyCollection(
      sort: { fields: title, order: ASC }
      limit: 100
    ) {
      nodes {
        ...CollectionCard
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
