import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import slugify from "@sindresorhus/slugify"
import {
  productHeadingStyle,
  productDetailsStyle
} from "./product-card.module.css"

export function CollectionCard({ product, eager }) {
  const {
    title,
    image
  } = product

  let imageAlt = "";

  if(image) {
    if(!image.altText) {
      imageAlt = title + " image";
    } else {
      imageAlt = image.altText;
    }
  }


  return (
    <Link
      className="productCardStyle"
      to={`/collections/${slugify(title)}`}
      aria-label={`View ${title} product page`}
    >
      <div className={productDetailsStyle}>
        {image && <GatsbyImage imgStyle={{borderRadius: "0"}} image ={image.gatsbyImageData} alt={imageAlt}/>}
        <h2 as="h2" className={productHeadingStyle}>
          {title}
        </h2>
      </div>
    </Link>
  )
}

export const query = graphql`
  fragment CollectionCard on ShopifyCollection {
    id
    title
    handle
    image {
        id
        altText
        gatsbyImageData
    }
    products {
        productType
        tags
    }
  }
`
